import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from "react-router-dom";

import './index.css';

import img1 from './jake-headshot-1.jpg';
import img2 from './coding-with-bunny1.jpg';
import img3 from './ses-machine-shop1.jpg';
import img4 from './rem-studios2-1.jpg';

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
      <div className="mainWrapper">
        <Router>
        <div className="mainHeader">
          <nav>
          <ul>
            <li>
              <NavLink to="/" className={({isActive}) => (isActive ? 'active' : 'inactive')}>Home</NavLink>
            </li>
            <li>
              <NavLink to="/developer" className={({isActive}) => (isActive ? 'active' : 'inactive')}>Developer</NavLink>
            </li>
            <li>
              <NavLink to="/musician" className={({isActive}) => (isActive ? 'active' : 'inactive')}>Musician</NavLink>
            </li>
          </ul>
          </nav>
        </div>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}

        <div className="mainContent">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/developer" element={<Developer />} />
            <Route path="/musician" element={<Musician />} />
          </Routes>
        </div>
        </Router>
        <div className="mainFooter">
          <p>©{new Date().getFullYear()} jakekilmer.com. This single page app is built with <a href="https://create-react-app.dev/" target="_blank" rel="noreferrer">Create React App</a>.</p>
        </div>
      </div>
  );
}
// You can think of these components as "pages"
// in your app

function Home() {
  return (
    <div>
      <h1>Jake Kilmer</h1>
      <div className="mcHIB1 mcHomeInnerBox">
        <img src={img1} className="mcHIImage" alt="Image" />
        <p>I am currently studying cyber security at Springboard online bootcamp, learning fundamentals to take the Security+ exam. Some areas I've really enjoyed are application security, OWASP top 10, security operations, network security, risk management, identity and access management and host based security.</p>
        <p>Check out my <NavLink to="/developer" className={({isActive}) => (isActive ? 'active' : 'inactive')}>Developer</NavLink> page to view some recent project and learn more. In my spare time I love to create <NavLink to="/musician" className={({isActive}) => (isActive ? 'active' : 'inactive')}>music</NavLink> with friends and family.</p>
      </div>
    </div>
  );
}

function Developer() {
  return (
    <div>
      <h1>Developer</h1>
      <img src={img2} className="mcHIImage2" alt="Image" />
      <p>I have been a full time front end web developer previously at Next Client since 2015 hand coding over 170 responsive sites and more. The languages I've worked with most are PHP, JS, CSS, JQuery and HTML. I also have experience with React, Node JS, SQL databases and queries. Recently I've been learning object oriented programing, application security and MVC in a Chores App project as well as learning some Python, PowerShell for security and automating.</p>
      <p>Follow me on <a href="https://www.linkedin.com/in/jacob-kilmer-3731896a/" target="_blank" rel="noreferrer">LinkedIn</a> and <a href="https://github.com/Jake-Kilmer" target="_blank" rel="noreferrer">Github</a>.</p>

      <h2>Recent Projects</h2>
      <div className="contentInnerBox">
        <h3>Wordpress Sites</h3>
        <ul className="secBlock1">
          <li><strong>Koether Law, P.A.</strong> <br></br>https://www.koetherlaw.com/</li>
          <li><strong>Connolly Injury Law</strong> <br></br>https://www.connollyinjurylaw.com</li>
          <li><strong>Blankingship & Christiano P.C.</strong> <br></br>https://www.yourfaircreditlawyer.com/</li>
          <li><strong>Shirazi Immigration Law</strong> <br></br>www.shirazilaw.com</li>
          <li><strong>Brezina Law</strong> <br></br>https://www.brezinalaw.com/</li>
        </ul>
        <ul className="secBlock2">
          <li><strong>Mark Brown Law</strong> <br></br> https://www.marcbrownlawfirm.com/</li>
          <li><strong>The Stanley Law Group</strong> <br></br>https://www.thestanleylawgroup.com/</li>
          <li><strong>Joshi Law Firm, PA</strong> <br></br>https://www.joshi-law.com/</li>
          <li><strong>Hawkins Spizman Trial Lawyers</strong> <br></br>https://www.hsflawfirm.com/</li>
        </ul>
      </div>
      <div className="contentInnerBox">
        <h3>For Fun</h3>
        <ul>
          <li><a href="/chores_app/">Chores App 1</a> - my very first super simple full stack app using Apache, MySQL and PHP. Version 1.4.</li>
          <li><a href="/projects/x_and_y/">Graph App 2</a> - vanilla JS, HTML and CSS. GUI needs improvement but the idea is there. Version 2.1.</li>
          <li><a href="/projects/picture_app_2/">Pictures App 2</a> - vanilla JS, HTML and CSS. Needs dynamic JS. Version 2.1.</li>
          <li><a href="/projects/slider_demo/">Slider App 1</a> - jQuery, HTML and CSS. My first slider attempt and needs to be re-coded in vanilla JS. Version 1.6</li>
        </ul>
      </div>

      <h2 className="clearBoth">Tools, Technologies and Concepts</h2>
      <div className="cIBoxOuter">
        <div className="contentInnerBox">
          <h3>Development</h3>
          <ul>
          <li>Object Oriented Programming</li>
          <li>MVC</li>
          <li>Javascript</li>
          <li>PHP</li>
          <li>React</li>
          <li>Node JS</li>
          <li>JQuery</li>
          <li>CSS</li>
          <li>MySQL/SQL</li>
          <li>HTML</li>
          <li>C++</li>
          <li>Wordpress</li>
          <li>SEO</li>
          </ul>
        </div>

        <div className="contentInnerBox">
          <h3>Security</h3>
          <ul className="secBlock1">
          <li>Python</li>
          <li>Powershell</li>
          <li>Software Development Lifecycle</li>
          <li>OWASP Top 10</li>
          <li>Web Application Security</li>
          <li>Database Security</li>
          <li>Penetration Testing</li>
          <li>CIS Controls</li>
          <li>NIST and other security frameworks</li>
          <li>OS Hardening</li>
          <li>Zero Trust Attacks</li>
          <li>TCP/IP Model,OSI Model</li>
          </ul>
          <ul className="secBlock2">
          <li>NMAP and Wireshark</li>
          <li>Firewall Administration and Encryption</li>
          <li>IDS/IPS</li>
          <li>Cloud Security, AWS, Azure</li>
          <li>Identity and Access Management (IAM), AWS, Azure</li>
          <li>Security Assessments: scanning with NIST Kali Linux</li>
          <li>Wireshark</li>
          <li>Splunk</li>
          <li>Burp Suite</li>
          <li>Sleuth Kit Autopsy</li>
          <li>Social Engineering</li>
          </ul>
        </div>
      </div>

    </div>
  );
}

function Musician() {
  return (
    <div>
      <h1>Musician</h1>

      <p>I come from a musical background and have been playing instruments, writing songs and recording since grade school. Before I was a developer, I played drums in a national rock band, Smile Empty Soul. I am extremely proud of the 5 albums and 2 e.p.'s we released. All of the touring was an incredible experience, not only as a musician, but also for the travel and friends I made along the way.</p>

      <img src={img3} className="mcHIImage3" alt="Image" />
      <p>I have been drumming since 3 years old, playing the couch pillows with my Mom's hair combs. With my Dad playing in garage bands (our garage), I was always exposed to instruments and recording equipment. My interest in music continued as I joined the junior high and high school bands (Marching/Jazz) and eventually joined/formed my own garage bands in high school. I also played two years of Big Band Jazz with Dirk Fischer at College of The Canyons, which had a big impact on my drumming.</p>

      <p>Rock/punk groups that I helped form, in or just after high school, include "Behind The Green Door", "The Airmen", "Revelations", "A Family Of Snail", "Nepo", and "The Flipper Babies"! December of 2005 I joined Smile Empty Soul and we rocked hard as a three piece up to 2016.</p>

      <p>Other awesome projets I have helped form, produce and/or play in are "Poet Warrior", "Johnny Bluegrass and The Coonhounds", "Jerry Danielsen", "Right At The Fork", "Tom Kilmer", "The Kilmer Family Band", "Girl Balls", and "Kill Cody". Right At The Fork is gearing up to release a new E.P this year!!</p>

      <img src={img4} className="mcHIImage4" alt="Image" />
      <p>Check out some demos I've released for fun on my <a href="https://www.youtube.com/user/TheMRAfia3/videos" target="_blank" rel="noreferrer">YouTube channel</a>. I'll be releasing more this year to put together an E.P.</p>
      
      <p>Follow what I'm up to musically on <a href="https://www.instagram.com/jake_kilmer/" target="_blank" rel="noreferrer">Instagram</a>, <a href="https://www.facebook.com/people/Jake-Kilmer/100063635426701/" target="_blank" rel="noreferrer">Facebook</a> and <a href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fjakekilmer.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Ejakekilmer&screen_name=jakekilmer" target="_blank" rel="noreferrer">Twitter</a>.</p>

      <h2>Bands/Artists</h2>
      <ul className="mcNewList secBlock1">
        <li><a href="https://music.apple.com/us/album/im-lost-usa/1518542502?i=1518542516" target="_blank" rel="noreferrer">Jake Kilmer</a></li>
        <li><a href="https://music.apple.com/us/artist/kill-cody/646678425" target="_blank" rel="noreferrer">Kill Cody</a></li>
        <li><a href="https://music.apple.com/us/artist/girl-balls/1453409028" target="_blank" rel="noreferrer">Girl Balls</a></li>
        <li><a href="https://open.spotify.com/artist/5ze89Axk6XAIliEGEyBwvI" target="_blank" rel="noreferrer">Tom Kilmer</a></li>
        <li><a href="https://open.spotify.com/artist/2m3fjcyvyPog2FdXXD0Ebm" target="_blank" rel="noreferrer">The Kilmer Family Band</a></li>
        <li><a href="https://music.apple.com/us/artist/right-at-the-fork/1190145793" target="_blank" rel="noreferrer">Right At The Fork</a></li>
        <li><a href="https://music.apple.com/us/artist/jerry-danielsen/313771012" target="_blank" rel="noreferrer">Jerry Danielsen</a></li>
        <li><a href="https://music.apple.com/us/album/whatever/897917459" target="_blank" rel="noreferrer">Poet Warrior</a></li>
        <li><a href="https://open.spotify.com/artist/2Yz7miHbxq0GDCMo3gxTdr" target="_blank" rel="noreferrer">Johnny Bluegrass and The Coonhounds</a></li>
      </ul>
      <ul className="mcNewList secBlock2">
        <li><a href="https://music.apple.com/us/artist/smile-empty-soul/1883765" target="_blank" rel="noreferrer">Smile Empty Soul</a></li>
        <li><a href="https://youtu.be/1wu1SUvhm9g" target="_blank" rel="noreferrer">The Flipper Babies</a></li>
        <li>Nepo</li>
        <li>A Family Of Snail</li>
        <li>Revelations</li>
        <li><a href="https://open.spotify.com/artist/4mI39fYzJ5abmdkqqxgUqZ" target="_blank" rel="noreferrer">The Airmen</a></li>
        <li>Behind The Green Door</li>
      </ul>
    </div>
  );
}